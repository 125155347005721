<template>
  <base-view>
    <div class="px-4">
      <div class="is-size-3">Задачи</div>

      <div class="py-2" v-if="$root.$user.usersettings.role == 'PR'">
        Чтобы редактировать таблицу, пройдите в <a href="/admin/app/task/">Админку</a>.
      </div>

      <b-table :data="tasks" 
               :columns="columns" 
               :mobile-cards="false"
               v-if="tasks">
        <template #empty>
          <div class="has-text-centered">Нет задач</div>
        </template>
      </b-table>
    </div>
  </base-view>
</template>

<script>
  import BaseView from  '@/views/BaseView.vue'

  export default {
    components: {
      BaseView,
    },
    data() {
      return {
        tasks: null,
        columns: [
          {
            field: 'id',
            label: 'ID',
            numeric: true,
            centered: true,
            'header-сlass': 'table-cell',
            'cell-class': 'table-cell',
          },
          {
            field: 'description',
            label: 'Задача',
          },
          {
            field: 'reward',
            label: 'Награда',
            centered: true,
          },
        ],
      }
    },
    async mounted() {
      const resp = await this.$api.tasks.get()
      this.tasks = resp.body
      console.log(this.tasks)
    },
  }
</script>
