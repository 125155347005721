<template>
  <base-view>
    <div class="px-4">
      <div class="is-size-3">Переводы</div>

      <div class="py-2">
        <b-button
          label="Перевести"
          type="is-primary"
          @click="newTransferShow()" />
      </div>
      
      <b-table :data="transfers" 
               :columns="columns" 
               :bordered="true"
               :narrowed="true"
               v-if="transfers">
        <template #empty>
          <div class="has-text-centered">Нет переводов</div>
        </template>
      </b-table>

      <b-modal has-modal-card :can-cancel="true" v-model="newTransferIsShown">
        <form action="">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <p class="modal-card-title">Перевод</p>
            </header>
            <section class="modal-card-body">
              <b-notification type="is-danger is-light" v-if="errorMessage">
                {{ errorMessage }}
              </b-notification>

              <b-field label="Кому">
                <b-select placeholder="Выберите пользователя" v-model="userTo">
                  <option
                      v-for="option in users"
                      :value="option.id"
                      :key="option.id">
                      {{ option.first_name }}
                  </option>
                </b-select>
              </b-field>

              <b-field label="Сумма">
                <b-input
                    type="number"
                    v-model="amount"
                    placeholder="Введите сумму"
                    required>
                </b-input>
              </b-field>

              <b-field label="Комментарий">
                <b-input
                    type="textarea"
                    v-model="comment"
                    placeholder="Введите комментарий">
                </b-input>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <b-button
                  label="Закрыть"
                  @click="newTransferIsShown = false" />
              <b-button
                  label="Перевести"
                  type="is-primary"
                  @click="transferClick()" />
            </footer>
          </div>
        </form>
      </b-modal>
    </div>
  </base-view>
</template>

<script>
  import BaseView from  '@/views/BaseView.vue'

  export default {
    components: {
      BaseView,
    },
    data() {
      return {
        newTransferIsShown: false,
        errorMessage: '',
        users: null,
        userTo: null,
        amount: null,
        comment: '',

        transfers: null,
        columns: [
          {
            field: 'id',
            label: 'ID',
            numeric: true,
            centered: true,
            'header-сlass': 'table-cell',
            'cell-class': 'table-cell',
          },
          {
            field: 'createrAtPretty',
            label: 'Когда',
          },
          {
            field: 'amount',
            label: 'Сумма',
          },
          {
            field: 'user_from_first_name',
            label: 'От кого',
          },
          {
            field: 'user_to_first_name',
            label: 'Кому',
          },
          {
            field: 'comment',
            label: 'Комментарий',
          },
        ],
      }
    },
    methods: {
      async updateTransferList() {
        const resp = await this.$api.transfers.get()
        this.transfers = resp.body
        this.transfers.map(transfer => {
          transfer.createrAtPretty = new Date(transfer.created_at).toLocaleString('ru-ru')
        })
      },

      newTransferShow() {
        this.userTo = null
        this.amount = null
        this.comment = ''
        this.errorMessage = ''
        this.newTransferIsShown = true
      },

      async transferClick() {
        this.errorMessage = ''

        if (this.userTo === null) {
          this.errorMessage = "Не указано, кому переводить."
          return
        }

        if (this.amount === null) {
          this.errorMessage = "Не указана сумма."
          return
        }

        const transfer = {
          user_from: this.$root.$user.id,
          user_to: this.userTo,
          amount: this.amount,
          comment: this.comment,
        }

        try {
          await this.$api.transfers.save(transfer)
        } catch (err) {
          this.errorMessage = err.body.detail
        }

        if (this.errorMessage == '') {
          this.newTransferIsShown = false
          this.$buefy.toast.open('Перевод сделан')
          await this.updateTransferList()
        }
      },
    },
    async mounted() {
      await this.updateTransferList()

      const respUsers = await this.$api.users.get()
      this.users = respUsers.body.filter(user => {
        return user.id != this.$root.$user.id
      })
    },
  }
</script>
