<template>
  <b-navbar type="is-dark" style="position: fixed; width: 100%;">
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img src="@/assets/logo.png">
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <b-icon icon="information-variant-circle" size="is-small" />
          &nbsp;
          Главная
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/transfers' }">
          <b-icon icon="menu" size="is-small" />
          &nbsp;
          Переводы
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/tasks' }">
          <b-icon icon="calendar-check" size="is-small" />
          &nbsp;
          Задачи
        </b-navbar-item>
        <b-navbar-item tag="a" href="/admin"
                       v-if="$root.$user.usersettings.role == 'PR'">
          <b-icon icon="security" size="is-small" />
          &nbsp;
          Админка
        </b-navbar-item>
      </template>
      <template #end>
        <b-navbar-item tag="a" to="#" @click="logoutClick()">
          <b-icon icon="logout" size="is-small" />
          &nbsp;
          Выйти
        </b-navbar-item>
      </template>
    </b-navbar>
</template>

<script>
  export default {
    methods: {
      logoutClick() {
        this.$buefy.dialog.confirm({
          title: 'Выход',
          message: 'Вы действительно хотите выйти?',
          confirmText: 'Выйти',
          cancelText: 'Отмена',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            await this.$api.users.logout()
            window.location.reload()
          }
        })
      },
    },
  }
</script>
