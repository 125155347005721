<template>
  <div>
    <header-div />

    <div style="padding-top: 50px;">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import HeaderDiv from '@/components/HeaderDiv.vue'

  export default {
    components: {
      HeaderDiv,
    },
  }
</script>
