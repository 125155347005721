<template>
  <base-view>
    <div class="px-4">
      <div class="is-size-3">Привет {{ $root.$user.first_name }}!</div>
      <div>
        <p>
          У тебя <strong>{{ $root.$user.usersettings.balance }}</strong> 
          {{ ruCoinsForm($root.$user.usersettings.balance) }}.
        </p>
      </div>
      <div>
        <img src="@/assets/logo-big.png">
      </div>
      <div>
        <p>
          Чтобы сделать перевод, пройди по 
          <router-link :to="{ path: '/transfers' }">ссылке</router-link>.
        </p>
      </div>
    </div>
  </base-view>
</template>

<script>
  import BaseView from  '@/views/BaseView.vue'

  export default {
    components: {
      BaseView,
    },
    methods: {
      ruCoinsForm(amount) {
        if (amount % 10 == 0) {
          return "монет"
        } else if ((amount % 100 >= 11) && (amount % 100 <= 14)) {
          return "монет"
        } else if (amount % 10 == 1) {
          return "монета"
        } else if (amount % 10 <= 4) {
          return "монеты"
        } else {
          return "монет"
        }
      },
    },
  }
</script>
